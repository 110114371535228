export default {
    name: 'header',
    component() {
        return {
            open: false,
            scrollTop: 0,
            visible: false,
            sticky: false,
            offset: 90,
            lastScrollTop: 0,
            subNav: false,
            subNavImage: "",
            subNavCollection: false,
            subNavImageMel: "",
            collectionImage: "",
            headerHeight: 0,

            init() {
                const header = document.querySelector(".header");

                this.setAbsolute();

                window.addEventListener("resize", () => {
                    this.setAbsolute();
                });
                
                if (document.querySelector("[data-header-menu]") != null) {
                    let headerMenu = document.querySelector("[data-header-menu]").dataset.headerMenu;
                    if(headerMenu === "true") {
                        header.classList.add("logo--white-md");
                        header.classList.add("menu--white");
                    }
                };

                if (document.querySelector("[data-header-logo]") != null) {
                    let headerLogo = document.querySelector("[data-header-logo]").dataset.headerLogo;
                    if(headerLogo === "true") {
                        header.classList.add("logo--white");
                    }
                };

                if (document.querySelector("[data-header-symbols]") != null) {
                    let headerSymbols = document.querySelector("[data-header-symbols]").dataset.headerSymbols;
                    if(headerSymbols === "true") {
                        header.classList.add("symbols--white");
                    }
                };

                if(document.querySelector("[data-link-handle]") != null) {
                    let firstLink = document.querySelector("[data-link-handle]").dataset.linkHandle;
                    this.subNavImage = firstLink;
                };

                if (window.innerWidth > 1000) {
                    setTimeout(() => {                    
                        const headerNavs = document.querySelectorAll('[data-subnav-list]');
                        let maxHeight = 0;
                        headerNavs.forEach(el => {
                            const height = el.getBoundingClientRect().height;
                            if (height > maxHeight) maxHeight = height;
                        })
                        headerNavs.forEach(el => {
                            el.style.height = `${maxHeight}px`;
                        })
                    }, 200);
                }

                this.stickyNav();
                window.addEventListener("scroll", () => {
                    this.stickyNav();
                });
            },


            setAbsolute() {
                const header = document.querySelector(".header");
                if (document.querySelector("[data-header-absolute]") != null) {
                    let headerPosition = document.querySelector("[data-header-absolute]").dataset.headerAbsolute;
                    if(headerPosition === "true") {
                        setTimeout(() => {
                            this.headerHeight = header.getBoundingClientRect().height;
                            header.style.marginTop = `-${this.headerHeight}px`;
                        }, "50");

                        setTimeout(() => {
                            header.classList.add("transition-all", "duration-500", "ease-in-out");
                        }, "150");
                    } else {
                        header.classList.add("transition-all", "duration-500", "ease-in-out");
                    }
                } else {
                    header.classList.add("transition-all", "duration-500", "ease-in-out");
                };
            },

            stickyNav() {
                const top =
                    window.scrollY ||
                    window.scrollTop ||
                    document.getElementsByTagName("html")[0].scrollTop;
                let st = window.pageYOffset || document.documentElement.scrollTop;
        
                if (top > this.offset) {
                    this.sticky = true;
                    if (st > this.lastScrollTop) {
                        this.visible = false;
                        this.lastScrollTop = st <= 0 ? 0 : st;
                    } else {
                        if (st + 100 < this.lastScrollTop) {
                            this.visible = true;
                            this.lastScrollTop = st <= 0 ? 0 : st;
                        }
                    }
                } else {
                    this.sticky = false;
                    this.visible = false;
                    this.lastScrollTop = st <= 0 ? 0 : st;
                }
            },
        }
    }
}