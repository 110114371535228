export default {
    name: 'global',
    store() {
        return {
            isMobileMenuVisible: false,
            isMinicartVisible: false,
            isPredictiveSearchVisible: false,
            isWindowScrolled: false,
            cart: null,
            cart_count: 0,
            body: "",
            showPasswordReset: false,
            swat: "",

            init() {
                window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))

                const cartOpen = new URLSearchParams(window.location.search).get('open');

                if(cartOpen == 'true') {
                    this.isMinicartVisible = true;
                }

                if(window.location.href.indexOf("#recover") != -1) {
                    this.showPasswordReset = true;
                }

                this.initLiquidAJaxCart();

                if (!window.SwymCallbacks) {
                    window.SwymCallbacks = [];
                }
                window.SwymCallbacks.push(this.onSwymLoadCallback);

                this.body = document.querySelector("body");

                if(document.querySelector("[data-coming-soon]") != null) {
                    if(document.querySelector("[data-coming-soon]").dataset.comingSoon == "true" )  {
                        let addToCart = document.querySelector("[data-prod-add]");
                        addToCart.style.display = "none";
                    }
                }
            },

            get bodyClasses() {
                let classes = []

                if (this.isMobileMenuVisible) {
                    classes.push('mobile-menu-visible')
                }

                return classes || ''
            },

            openMobileMenu() {
                this.isMobileMenuVisible = true
                this.enableBodyScrollLock()
            },

            closeMobileMenu() {
                this.isMobileMenuVisible = false
                this.disableBodyScrollLock()
            },

            toggleMobileMenu() {
                this.isMobileMenuVisible = !this.isMobileMenuVisible

                if(this.isMobileMenuVisible) {
                    this.enableBodyScrollLock()
                } else {
                    this.disableBodyScrollLock()
                }
            },

            onSwymLoadCallback(swat) {
                // Please make all SWYM-related API calls inside this function.
                // You can use the swat argument within this scope.
                if (swat) {
                    window.swat = swat;
                    window.Alpine.store('global').getWishlist();
                }
            },

            initLiquidAJaxCart() {
                document.addEventListener("liquid-ajax-cart:request-end", (event) => {
                    const { requestState, cart, previousCart, sections } = event.detail;

                //   console.log(requestState.requestType);

                    if (requestState.requestType === 'add') {
                        if (requestState.responseData?.ok) {
                            this.isMinicartVisible = true;
                            this.enableBodyScrollLock();
                        }
                    }
                    this.cart = cart
                    this.cart_count = this.cart.item_count

                    console.log("cart");
                    console.log(this.cart.item_count);
                });
            },

            onWindowScrollHandler() {
                const isScrolled = window.scrollY > 0

                this.isWindowScrolled = isScrolled
                document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
            },


            enableBodyScrollLock() {
                this.scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                this.body.style.overflow = "hidden";
                this.body.style.position = "fixed";
                this.body.style.top = `-${this.scrollTop}px`;
                this.body.style.width = "100%";
            },
        
            disableBodyScrollLock() {
                this.body.style.removeProperty("overflow");
                this.body.style.removeProperty("position");
                this.body.style.removeProperty("top");
                this.body.style.removeProperty("width");
                window.scrollTo(0, this.scrollTop);
            },

            closeCart() {
                this.isMinicartVisible = false;
                this.disableBodyScrollLock();

                const url = new URL(window.location);
                url.searchParams.set("open", false);
                window.history.pushState({}, "", url);
            }
        }
    }
}