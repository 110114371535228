export default {
    name: 'instagram',
    component() {
        return {
            error: false,
            results: [],
            accessToken: "IGQWROR0E3Q1lPaWd0VHhRbWJmOVNVQTczQkxPOW5oUGJpa3JVX2dEUTloaE1WRUxtQ0p1RFEzYXRXdzRGQUNlU0toQTR0U0JPRFNONWptajVqekZAEV0I2RXZAwa05RZAndpYkpLY2FjS05Gd0thUEdzNmpmR0c4akUZD",
           
            init() {
                // this.getInstagramFeed();
            },

            getInstagramFeed() {
                fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,media_type,media_url,permalink,timestamp&limit=10&access_token=${this.accessToken}`)
                .then(response => response.json())
                .then(data => {
                    if (data.error) {                    
                        this.error = true;
                    } else {
                        this.results = data.data;
                    }
                })
                .catch(error => {
                    this.error = true;
                });
            },
        }
    }
}